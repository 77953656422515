h5.tag-list-header {
  font-size: 0.85rem;
}

ul.tag-list {
  gap: 0;
  margin: 0 -0.5rem 0.25rem;

  li {
    border: 0;
    border-radius: 0;
    width: 100%;
    background: none;

    a {
      font-size: 0.85rem;
      line-height: 1rem;
    }
  }

  // Section 1: Controls
  .tag-list-wiki,
  .tag-list-inc,
  .tag-list-exl {
    @include st-padding-lr;
  }

  // Section 2: Tag Data
  .tag-list-search {
    display: block;
    @include st-padding-lr;

    .tag-list-name {
      border: 0;
      padding: 0;
    }

    .tag-list-count {
      display: inline-block;
      @include st-padding-lr;
    }
  }

  // Section 3: Actions
  .tag-list-actions {
    display: flex;

    button {
      padding: 0 0.075rem;
      svg {
        width: 0.85rem;
        height: 0.85rem;
      }
    }
  }

  .tag-list-quick-blacklist { visibility: hidden; }
  li:hover, li.blacklisted {
    .tag-list-quick-blacklist { visibility: visible; }
  }
  li.blacklisted {
    .tag-list-quick-blacklist svg {
      color: themed("color-text-muted");
      &:hover { color: themed("color-link-hover"); }
      .line { color: transparent; }
    }
  }
}
