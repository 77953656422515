// Desktop-only
// Rollout at 50rem
.post-index {
  grid-template-areas:
    "search  content" 
    "sidebar content";
  grid-template-columns: 16rem 1fr;
  grid-template-rows: min-content 1fr;

  .search {
    box-shadow: inset -0.25rem 0px 0.25rem -0.25rem themed("color-background");
    padding: 0.5rem 0.75rem 0.5rem 0.5rem;

    // Align the controls properly
    position: relative;

    .search-controls {
      display: flex;
      flex-flow: row;
      justify-content: right;
      z-index: 1;

      position: absolute;
      bottom: -1.633rem;
      right: 0.5rem;

      padding: 0.25rem;
      gap: 0.5rem;

      background: themed("color-foreground");
      border-radius: 0 0 0.25rem 0.25rem;

      button {
        height: 1.5rem;
        padding: 0;

        svg {
          height: 1.25rem;
          width: 1.25rem;
          padding: 0.25rem;
          margin: -0.125rem 0;
        }
      }
    }
  }

  .sidebar {
    box-shadow: inset -0.25rem 0px 0.25rem -0.25rem themed("color-background");
    padding: 0.5rem 0.75rem 0.5rem 0.5rem;
  }

  .content {
    border-radius: 0 0.25rem 0.25rem 0;
    padding: 0.5rem 0.75rem themed("content-padding-bottom");
  }
}
