h5.tag-list-header {
  font-size: 1.25rem;
  cursor: pointer;

  &.hidden-category::before {
    content: "► ";
  }
}

ul.tag-list {
  display: flex;
  flex-flow: column;
  gap: 0.5rem 0;
  margin: 0.5rem 0;

  li {
    display: flex;
    width: fit-content;

    background: themed("color-section");
    border: 0.125rem solid themed("color-section-lighten-5");
    border-left-width: 0.25rem;
    @include st-radius;

    @each $tag-category, $tag-category-name in $tag-categories {
      &.tag-#{$tag-category-name} {
        border-left-color: themed("color-tag-#{$tag-category-name}");
        &:hover { border-left-color: themed("color-tag-#{$tag-category-name}-alt"); }

        a, a:link { color: themed("color-tag-#{$tag-category-name}"); }
        a:hover, a:active { color: themed("color-tag-#{$tag-category-name}-alt"); }
      }
    }

    a {
      font-size: 1rem;
      line-height: 1.25rem;
    }
  }

  // Section 1: Controls
  .tag-list-wiki,
  .tag-list-inc,
  .tag-list-exl,
  .tag-list-count {
    @include st-padding-yx(025, 050);
    user-select: none;
  }

  // Section 2: Tag Data
  .tag-list-search {
    display: flex;

    .tag-list-name {
      border: 1px solid themed("color-section-lighten-5");
      border-width: 0 1px 0 1px;
      @include st-padding;

      .hisc { color: transparent; }
    }

    .tag-list-count {
      color: themed("color-text-muted");
      font-size: 85%;
      &.low-post-count { color: palette("text-red"); }
    }
  }

  // Section 3: Actions
  .tag-list-actions {
    display: none; // flex
    align-items: baseline;
    margin-left: auto;

    button {
      display: flex;
      align-items: center;
      color: themed("color-link");

      // default button styles
      padding: 0;
      border-radius: 0;
      background: none;

      &:hover { color: themed("color-link-hover"); }
      
      svg {
        width: 1rem;
        height: 1rem;
      }
    }
  }
}
